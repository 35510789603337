// Add your custom JS here.

//Import AOS Libary
import AOS from 'aos';
AOS.init({
        // Global settings:
        delay: 0, // values from 0 to 3000, with step 50ms
        duration: 1000, // values from 0 to 3000, with step 50ms
        easing: 'ease', // default easing for AOS animations
        once: false, // whether animation should happen only once - while scrolling down
        disable: 'mobile'
    }
);
